// React Basic and Bootstrap
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import i18next from '../../i18n';

//images
import finance1 from '../../assets/images/smg_1.jpg';
import finance2 from '../../assets/images/smg_2.jpg';

const QualityAssurance = () => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container className="mt-60">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <i className="uil uil-chart-pie-alt text-primary h2"></i>
                <h4 className="title mt-3 mb-4">{i18next.t('QualityAssurance')}</h4>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={6} className="mt-2">
              <div className="px-md-4 pt-4 text-center">
                <h4 className="mb-3">{i18next.t('ControlledProduction​')}​</h4>
                <p className="text-muted mb-0">
                {i18next.t('ControlledProduction​Text')}
                </p>

                <div className="position-relative mt-5 hoveredImg">
                  <div
                    className="bg-overlay bg-primary bg-gredient rounded-md"
                    style={{ opacity: '0.4' }}
                  ></div>
                  <img
                    src={finance1}
                    className="rounded-md shadow img-fluid"
                    alt=""
                  />
                </div>

                <div className="px-md-4 pt-4 text-start">
                  <div className="d-flex mt-4">
                    <i className="uil uil-create-dashboard h5 text-primary"></i>
                    <div className="flex-1 ms-2">
                      <h5>{i18next.t('TrustedQCTestCenters')}</h5>
                      <p className="mb-0 text-muted">
                        {i18next.t('TrustedQCTestCentersText')} 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={6} className="mt-2">
              <div className="px-md-4 pt-4 text-center">
                <h4 className="mb-3">{i18next.t('ContinuousImprovement')}</h4>
                <p className="text-muted mb-0">
                {i18next.t('ContinuousImprovementText')}
                </p>
                <div className="position-relative mt-5 hoveredImg">
                  <div
                    className="bg-overlay bg-primary bg-gredient rounded-md "
                    style={{ opacity: '0.4' }}
                  ></div>
                  <img
                    src={finance2}
                    className="rounded-md shadow img-fluid"
                    alt=""
                  />
                </div>

                <div className="px-md-4 pt-4 text-start">
                  <div className="d-flex mt-4">
                    <i className="uil uil-create-dashboard h5 text-primary"></i>
                    <div className="flex-1 ms-2">
                      <h5>{i18next.t('TechnicalCompliantFactoriesAndSuppliers')}</h5>
                      <p className="mb-0 text-muted">
                      {i18next.t('TechnicalCompliantFactoriesAndSuppliersText')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default QualityAssurance;
