import React, { Component, Suspense } from 'react';
import { Col, Container, Row } from 'reactstrap';
import i18next from '../../i18n';

//Import Icons
import { Link } from 'react-router-dom';

//Import Switcher
import BackToTop from './backToTop';
import * as Unicons from '@iconscout/react-unicons';

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
    this.callNumber2.bind(this);
  }
  sendMail() {
    window.location.href = 'mailto:info@rspacific.net';
  }

  callNumber() {
    window.location.href = 'tel:+852-3101-9961';
  }

  callNumber2() {
    window.location.href = 'tel:+852-6050-0533';
  }

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <footer className="footer">
            <Container>
              <Row className="justify-content-center">
                <Col className="col-12">
                  <div className="footer-py-60 text-center">
                    <Row>
                      <Col md={4}>
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <i className="uil uil-phone d-block rounded h3 mb-0">
                              <Unicons.UilPhone size="30" />
                            </i>
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">{i18next.t('Phone')}</h5>
                            <Link
                              to="#"
                              onClick={this.callNumber}
                              className="text-foot"
                            >
                              +852 3101 9961
                            </Link>                            
                          </div>
                          <div>
                          <Link
                              to="#"
                              onClick={this.callNumber2}
                              className="text-foot"
                            >
                              +852 6050 0533
                            </Link>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <i className="uil uil-envelope d-block rounded h3 mb-0">
                              <Unicons.UilEnvelope size="30" />
                            </i>
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">{i18next.t('Email')}</h5>
                            <Link
                              to="#"
                              onClick={this.sendMail}
                              className="text-foot"
                            >
                              info@rspacific.net
                            </Link>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-primary feature-clean bg-transparent">
                          <div className="icons text-center mx-auto">
                            <i className="uil uil-map-marker d-block rounded h3 mb-0">
                              <Unicons.UilMapMarker size="30" />
                            </i>
                          </div>
                          <div className="content mt-4">
                            <h5 className="footer-head">{i18next.t('Location')}</h5>
                            <p>Room F, 37/F, COS Centre, 56 Tsun Yip Street, Kwun Tong, Kowloon, Hong Kong</p>
                            {/* <Link to="//https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                              data-type="iframe" className="video-play-icon text-foot lightbox">View on Google map</Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="footer-py-15">
              <Container className="text-center">
                <Row className="align-items-center justify-content-between">
                  <Col className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="text-sm-end">
                      <p className="mb-0 text-foot">
                        © {new Date().getFullYear()}{' '}
                        <Link to="#" rel="noreferrer" className="text-reset">
                          RS Pacific Limited
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>

          <BackToTop />
          {/* theme switcher */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Footer;
