// React Basic and Bootstrap
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//Import Images
import corporate from '../../assets/images/corporate/pages.jpg';

export default function Breadcrumb(props) {
  return (
    <>
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${corporate}) top` }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading title-heading">
                <h2 className="text-white title-dark"> {props.title} </h2>
                <p className="text-white-50 para-desc mb-0 mx-auto">
                  {props.desc}
                </p>
              </div>
            </Col>
          </Row>

          <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb bg-white rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item">
                  <Link to="/">RsPasific</Link>
                </li>{' '}
                <li className="breadcrumb-item active" aria-current="page">
                  {props.title}
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
}
