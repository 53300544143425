// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import i18next from '../../i18n';

//Import components
import SectionTitle from '../../components/Shared/SectionTitle';

import * as Unicons from '@iconscout/react-unicons';

class KeyValues extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-75 " style={{ marginBottom: '50px' }}>
          <SectionTitle title={i18next.t('KeyValues')} />
          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean bg-transparent process-arrow text-center">
                <div
                  className="icons text-primary text-center mx-auto"
                  style={{ border: '1px solid', borderRadius: '6px' }}
                >
                  <i className="d-block rounded h3 mb-0">
                    <Unicons.UilUserArrows size="35" />
                  </i>
                </div>

                <CardBody>
                  <h5 className="text-dark">{i18next.t('KeyValuesText1')}</h5>
                  {/* <p className="text-muted mb-0">
                        The most well-known dummy text is the 'Lorem Ipsum',
                        which is said to have originated
                      </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean bg-transparent process-arrow text-center">
                <div
                  className="icons text-primary text-center mx-auto"
                  style={{ border: '1px solid', borderRadius: '6px' }}
                >
                  <i className="d-block rounded h3 mb-0">
                    <Unicons.UilRocket size="35" />
                  </i>
                </div>

                <CardBody>
                  <h5 className="text-dark">{i18next.t('KeyValuesText2')}</h5>
                  {/* <p className="text-muted mb-0">
                        The most well-known dummy text is the 'Lorem Ipsum',
                        which is said to have originated
                      </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean bg-transparent process-arrow text-center">
                <div
                  className="icons text-primary text-center mx-auto"
                  style={{ border: '1px solid', borderRadius: '6px' }}
                >
                  <i className="d-block rounded h3 mb-0">
                    <Unicons.UilStar size="35" />
                  </i>
                </div>

                <CardBody>
                  <h5 className="text-dark">
                  {i18next.t('KeyValuesText3')}
                  </h5>
                  {/* <p className="text-muted mb-0">
                        The most well-known dummy text is the 'Lorem Ipsum',
                        which is said to have originated
                      </p> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default KeyValues;
