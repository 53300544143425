import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import * as Unicons from '@iconscout/react-unicons';
import i18next from '../../i18n';

export default class HomeVisionMissionValues extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" style={{ paddingBottom: '1rem' }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="features-absolute">
                  <Row>
                    <Col lg={4} md={6} xs={12}>
                      <Card className="card features feature-primary feature-clean res fea-primary rounded p-4 position-relative overflow-hidden shadow xClass">
                        <span className="h1 icon2 text-primary">
                          <Unicons.UilBriefcase size="40"/>
                        </span>
                        <CardBody className="p-0 content">
                          <h5>{i18next.t('OurVision')}</h5>
                          <p className="para text-muted mb-0">
                              {i18next.t('OurVisionSliderText')}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                      <Card className="card features feature-primary feature-clean res fea-primary rounded p-4 position-relative overflow-hidden shadow xClass">
                        <span className="h1 icon2 text-primary">
                          <Unicons.UilRocket size="40"/>
                        </span>
                        <CardBody className="p-0 content">
                          <h5>{i18next.t('OurMission')}</h5>
                          <p className="para text-muted mb-0">
                              {i18next.t('OurMissionSliderText')}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                      <Card className="card features feature-primary feature-clean res fea-primary rounded p-4 position-relative overflow-hidden shadow xClass">
                        <span className="h1 icon2 text-primary">
                          <Unicons.UilCrosshairs size="40"/>
                        </span>
                        <CardBody className="p-0 content">
                          <h5>{i18next.t('OurValues')}</h5>
                          <p className="para text-muted mb-0">
                              {i18next.t('OurValuesSliderText')}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
