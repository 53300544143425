// React Basic and Bootstrap
import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';


class Map extends Component {
  render() {
    return (
      <React.Fragment>
        <section style={{ marginTop: '1px' }}>
        <Container fluid style={{ padding: 0 }}>
          <div
            className="bg-map shadow rounded card overflow-hidden"
            id="cta"
          >
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" className="text-center">
                  
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Map;
