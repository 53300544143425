// React Basic and Bootstrap
import React, { useEffect } from 'react';

//Import components
import Negotiation from './Negotiation';
import Support from './Support';
import Logistic from './Logistic';
import QualityAssurance from './QualityAssurance';
import Sourcing from './Sourcing';
import Breadcrumb from './../Components/Breadcrumb';
import i18next from '../../i18n';

const Services = () => {
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };
  return (
    <React.Fragment>
      {/* breadcrumb */}
      <Breadcrumb
        title={i18next.t('Services')}
        desc={i18next.t('WeAreRsPacificText')}
      />

      <Sourcing></Sourcing>

      <QualityAssurance></QualityAssurance>

      <Negotiation></Negotiation>

      <Support></Support>

      <Logistic></Logistic>
    </React.Fragment>
  );
};
export default Services;
