// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import i18next from '../../i18n';

//Import components
import SectionTitle from '../../components/Shared/SectionTitle';

import sourcing1 from '../../assets/images/sourcing1.jpg';
import sourcing2 from '../../assets/images/sourcing2.jpg';
import sourcing3 from '../../assets/images/sourcing3.jpg';
import sourcing4 from '../../assets/images/sourcing4.jpg';

const Sourcing = () => {
  return (
    <React.Fragment>
      <section className="section">
        <SectionTitle title={i18next.t('Sourcing')}></SectionTitle>
        <Container>
          <Row className="align-items-center">
            <Col lg={7} xs={12} className="col--6">
              <Row className="me-lg-4" id="counter">
                <Col md={6} xs={12}>
                  <Row>
                    <Col xs={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                        <div className="position-relative">
                          <img
                            src={sourcing4}
                            className="rounded-md shadow img-fluid"
                            alt=""
                          />
                          <div className="overlaySb"></div>
                        </div>
                        <div className="author">
                          <small className="text-light user d-block">
                            <i className="uil uil-arrow-right"></i> {i18next.t('SourcingExperts')}
                          </small>
                        </div>
                      </Card>
                    </Col>

                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                        <div className="position-relative">
                          <img
                            src={sourcing1}
                            className="rounded-md shadow img-fluid"
                            alt=""
                          />
                          <div className="overlaySb"></div>
                        </div>
                        <div className="author">
                          <small className="text-light user d-block">
                            <i className="uil uil-arrow-right"></i> {i18next.t('ScoutSupplierAndManageOrders')}
                          </small>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col md={6} xs={12}>
                  <Row className="pt-lg-4 mt-lg-4">
                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                        <div className="position-relative">
                          <img
                            src={sourcing2}
                            className="rounded-md shadow img-fluid"
                            alt=""
                          />
                          <div className="overlaySb"></div>
                        </div>
                        <div className="author">
                          <small className="text-light user d-block">
                            <i className="uil uil-arrow-right"></i> {i18next.t('DevelopNewProducts')}
                          </small>
                        </div>
                      </Card>
                    </Col>

                    <Col xs={12} className="mt-4 pt-2">
                      <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                        <div className="position-relative">
                          <img
                            src={sourcing3}
                            className="rounded-md shadow img-fluid"
                            alt=""
                          />
                          <div className="overlaySb"></div>
                        </div>
                        <div className="author">
                          <small className="text-light user d-block">
                            <i className="uil uil-arrow-right"></i> {i18next.t('SourceProducts')}
                          </small>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={5} xs={12} className="col--6 mt-4 pt-2 mt-lg-0 pt-lg-0">
              <div className="section-title ms-lg-4">
                <p className="text-primary h2 mb-3">
                  <i className="uil uil-apps"></i>
                </p>
                <h4 className="title mb-3">{i18next.t('SourcingExperts')}</h4>
                <p className="text-muted">
                  {i18next.t('ElectronicComponentsSourcingText1')}
                </p>
                <p className="text-muted">
                  {i18next.t('ElectronicComponentsSourcingText2')}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Sourcing;
