import React, { Component } from 'react';
//Import file
import HomeSlider from './HomeSlider';
import HomeVisionMissionValues from './HomeVisionMissionValues';
import HomeServices from './HomeServices';
import HomeOurPurpose from './HomeOurPurpose';
import HomeCta from './HomeCta';

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        {/* import HomeSlider */}
        <HomeSlider />

        {/* import VisionMissionValues */}
        <HomeVisionMissionValues />

        {/* import HomeOurPurpose */}
        <HomeOurPurpose />

        {/* import HomeServices */}
        <HomeServices />
        
        {/* import HomeCta */}
        <HomeCta />

      </React.Fragment>
    );
  }
}
