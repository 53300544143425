// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import i18next from '../../i18n';
// import images
import about from '../../assets/images/about.jpg';

class OurPurpose extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col lg={4} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="position-relative">
                  <img
                    src={about}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>

              <Col lg={8} md={8} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  <span className="badge rounded-pill bg-soft-primary" style={{marginBottom:"10px"}}>
                  {i18next.t('OurPurpose')}
                  </span>
                  <h4 className="title mb-4">{i18next.t('OurPurpose')}</h4>
                  <p className="text-muted">
                  {i18next.t('OurPurposePage1')}
                  </p>
                  <p className="text-muted">
                  {i18next.t('OurPurposePage2')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default OurPurpose;
