import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import i18next from '../../i18n';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import * as Unicons from '@iconscout/react-unicons';
//import image
import about1 from '../../assets/images/target.jpg';
import SectionTitle from '../../components/Shared/SectionTitle';

export default class HomeOurPurpose extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            <SectionTitle title={i18next.t("OurPurpose")} desc="" />
            <Row className="align-items-center">
              <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="position-relative hoveredImg">
                  <img
                    src={about1}
                    className="img-fluid mx-auto"
                    style={{ borderRadius: '60px' }}
                    alt=""
                  />
                </div>
              </Col>

              <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  <ul className="list-unstyled mb-0 text-muted">
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT1")}
                    </li>
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT2")}
                    </li>
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT3")}
                    </li>
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT4")}
                    </li>
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT5")}
                    </li>
                    <li className="text-muted">
                      <i className="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2">
                        <Unicons.UilArrowRight size="20" />
                      </i>
                      {i18next.t("OurPurposeT6")}
                    </li>
                  </ul>
                  <Link to="/about-us" className="btn btn-primary mt-3">
                  {i18next.t("AboutUs")}{' '}
                    <i>
                      <FeatherIcon
                        icon="chevron-right"
                        className="fea icon-sm"
                      />
                    </i>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
