// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import i18next from '../../i18n';

//Import Slick Slider
import Slider from 'react-slick';

//Import Slick Slider CSS
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

// import images
import log from '../../assets/images/bg_rs_1.jpg';
import optic from '../../assets/images/bg_rs_2.jpg';
import nego from '../../assets/images/bg_rs_3.jpg';

class HomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      items: [
        {
          id: 1,
          title: i18next.t('QualityAssurance'),
          desc: i18next.t('QualityAssuranceSliderText'),
        },
        {
          id: 2,
          title: i18next.t('Sourcing'),
          desc: i18next.t('SourcingSliderText'),
        },
        {
          id: 3,
          title: i18next.t('Logistics'),
          desc: i18next.t('LogisticsSliderText'),
        }
      ],
    };
  }

  componentDidMount() {
    var e1 = document.getElementsByClassName('slick-slide');
    for (var i = 0; i < 3; i++) {
      e1[i].style.backgroundSize = "cover";
      e1[i].style.backgroundRepeat = "no-repeat";
      e1[i].style.backgroundPosition = "center";
      if (i === 0) e1[i].style.backgroundImage = `url(${optic})`;
      if (i === 1) e1[i].style.backgroundImage = `url(${nego})`;
      if (i === 2) e1[i].style.backgroundImage = `url(${log})`;
    }
  }

  render() {
    var settings = {
      autoplay: true,
      infinite: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      draggable: false,
      pauseOnHover: false,
    };
    return (
      <React.Fragment>
        <section className="swiper-slider-hero position-relative d-block">
          <Slider className="slides" {...settings}>
            {this.state.items.map((item, key) => (
              <div
                key={key}
                className="vh-100 slide-inner slide-bg-image d-flex align-items-center"
              >
                <div className="bg-overlay"></div>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg="12">
                      <div className="title-heading text-center">
                        <h1 className="heading text-white title-dark mb-4">
                          {item.title}
                        </h1>
                        <p className="para-desc mx-auto text-white-50">
                          {item.desc}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </Slider>
        </section>
      </React.Fragment>
    );
  }
}

export default HomeSlider;
