// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import i18next from '../../i18n';

// import images
import img2 from '../../assets/images/it/cta.jpg';

class CtaAbout extends Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid style={{ padding: 0 }}>
          <div
            className="bg-cta shadow rounded card overflow-hidden"
            style={{ background: `url(${img2}) center center` }}
            id="cta"
          >
            <div className="bg-overlay bg-primary-gradient-overlay"></div>
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" className="text-center">
                  <div className="section-title">
                    <h4 className="title title-dark text-white mb-4">
                    {i18next.t('OurVision')}
                    </h4>
                    <p className="text-white-50 para-dark para-desc mx-auto">
                    {i18next.t('OurVisionPageText')}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default CtaAbout;
