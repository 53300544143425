// React Basic and Bootstrap
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

//Import components
import SectionTitle from '../../components/Shared/SectionTitle';
import i18next from '../../i18n';

//Import Images
import about from '../../assets/images/logistics/about.png';
import img3 from '../../assets/images/lojistik.jpg';
const Logistic = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container className="">
          <SectionTitle title={i18next.t('Logistics')}></SectionTitle>

          <Row className="align-items-center">
            <Col lg={4} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="position-relative">
                <img
                  src={about}
                  className="rounded img-fluid mx-auto d-block hoveredImg"
                  alt=""
                />
              </div>
            </Col>

            <Col lg={4} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <h4 className="title mb-4 text-center">
                  <span className="text-primary">RS</span>PACIFIC
                </h4>
                <p className="text-muted text-center">
                {i18next.t('ProvideGlobalLogisticsSolutions')}<br></br>
                {i18next.t('MonitoringSeaTrainAirShipment')}
                </p>
              </div>
            </Col>

            <Col lg={4} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="position-relative">
                <img
                  src={img3}
                  className="rounded img-fluid mx-auto d-block hoveredImg"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Logistic;
