
//Main Index
import Home from "../pages/MainPage/Home";
import AboutUs from "../pages/PageAbout/AboutUs";
import Services from "../pages/PageServices/Services";
import Contact from "../pages/PageContact/Contact";

const routes = [
  { path: "/", component: Home },
  { path: "/about-us", component: AboutUs },
  { path: "/services", component: Services },
  { path: "/contact", component: Contact },
];

export default routes;
