// React Basic and Bootstrap
import React, { Component } from 'react';

// import images  
import CtaAbout from './CtaAbout';
import Breadcrumb from './../Components/Breadcrumb';
import OurPurpose from './OurPurpose';
import KeyValues from './KeyValues';
import i18next from '../../i18n';
class AboutUs extends Component {
  componentDidMount() {
    document.body.classList = '';
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <Breadcrumb
        title={i18next.t('AboutUs')}
        desc={i18next.t('AboutUsPageText')}
      />
        <OurPurpose></OurPurpose>

        <CtaAbout></CtaAbout>
        
        <KeyValues></KeyValues>

      </React.Fragment>
    );
  }
}
export default AboutUs;
