// React Basic and Bootstrap
import React, { Component } from 'react';

import Breadcrumb from './../Components/Breadcrumb';
import FormContact from './FormContact';
import Map from './Map';
import i18next from '../../i18n';

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumb
        title={i18next.t('Contact')}
        desc={i18next.t('WeAreRsPacificText')}
      />
        <FormContact></FormContact>
        <Map></Map>
      </React.Fragment>
    );
  }
}

export default Contact;
