// React Basic and Bootstrap
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import * as Unicons from '@iconscout/react-unicons';
import i18next from '../../i18n';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';

const HomeServices = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* section title */}
          <SectionTitle title={i18next.t('OurServices')} desc="" />
          <Row>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilEditAlt size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('Sourcing')}</h5>
                  <p className="text-muted mb-0">
                    {i18next.t('SourcingSliderText')}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilVectorSquare size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('QualityAssurance')}</h5>
                  <p className="text-muted mb-0">
                  {i18next.t('QualityAssuranceSliderText')}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilFileSearchAlt size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('Negotiation')}</h5>
                  <p className="text-muted mb-0">
                    {i18next.t('NegotiationText')}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilShip size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('Logistics')}</h5>
                  <p className="text-muted mb-0">
                    {i18next.t('LogisticsSliderText')}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilLifeRing size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('Support')}</h5>
                  <p className="text-muted mb-0">   
                    {i18next.t('SupportText')}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="col-12 mt-5">
              <div className="features feature-primary text-center">
                <div className="image position-relative d-inline-block">
                  <i className="h2 text-primary">
                    <Unicons.UilCheckCircle size="35" />
                  </i>
                </div>

                <div className="content mt-4">
                  <h5>{i18next.t('ProblemSolving')}</h5>
                  <p className="text-muted mb-0">
                      {i18next.t('ProblemSolvingText')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HomeServices;
