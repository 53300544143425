// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';
import SectionTitle from '../../components/Shared/SectionTitle';
import i18next from '../../i18n';

// import images
import contact from '../../assets/images/contact.jpg';
import { Link } from 'react-router-dom';

class FormContact extends Component {
  constructor(props) {
    super(props);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
    this.callNumber2.bind(this);
  }
  sendMail() {
    window.location.href = 'mailto:info@rspacific.net';
  }

  callNumber() {
    window.location.href = 'tel:+852-3101-9961';
  }

  callNumber2() {
    window.location.href = 'tel:+852-6050-0533';
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <Container className="mt-50" style={{ marginBottom: '50px' }}>
            <SectionTitle title={i18next.t('Contact')}></SectionTitle>
            <Row className="align-items-center">
              <Col lg={6} md={{ size: 6, order: 1 }} xs={{ order: 1 }}>
                <div className="title-heading ms-lg-4">
                  <h4 className="mb-4">{i18next.t('ContactDetails')}</h4>
                  <p className="text-muted">
                    {i18next.t('Contact')}
                  </p>
                  <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">{i18next.t('Email')}</h6>
                      <Link onClick={this.sendMail} to="#" className="text-primary ">
                        info@rspacific.net
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">{i18next.t('Phone')}</h6>
                      <div>
                      <Link onClick={this.callNumber} to="#" className="text-primary ">
                        +852 3101 9961
                      </Link>
                      </div>
                      <div>
                      <Link onClick={this.callNumber2} to="#" className="text-primary ">
                        +852 6050 0533
                      </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">{i18next.t('Location')}</h6>
                      <span style={{ color: '#3f873f' }}>
                      Room F, 37/F, COS Centre, 56 Tsun Yip Street, Kwun Tong, Kowloon, Hong Kong
                      </span>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={{ size: 6, order: 2 }} xs={{ order: 2 }}>
                <Card className="border-0 text-center">
                  <CardBody className="p-0">
                    <img
                      src={contact}
                      className="img-fluid"
                      alt="rspacific"
                      style={{ maxWidth: '400px', borderRadius: '60px' }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FormContact;
