// React Basic and Bootstrap
import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
//Import components
import SectionTitle from '../../components/Shared/SectionTitle';
import i18next from '../../i18n';

//Import Images
import work1 from '../../assets/images/simg_1.jpg';
import work2 from '../../assets/images/simg_2.jpg';
import work3 from '../../assets/images/simg_3.jpg';


const Support = () => {
  
  return (
    <React.Fragment>
     
      <section className="section bg-light" style={{ paddingBottom: '50px' }}>
        <Container className="">
          <SectionTitle title={i18next.t('Support')}></SectionTitle>

          <Row className="justify-content-center">
            <Col xs={12}></Col>
          </Row>

          <Row>
            <Col md={4} className="pt-2">
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center">
                  <img
                    src={work1}
                    className="rounded img-fluid d-block  mx-auto hoveredImg"
                    alt=""
                    style={{ width: '400px' }}
                  />
                </div>

                <CardBody>
                  <h5 className="text-dark">{i18next.t('CustomerMeeting')}</h5>
                </CardBody>
              </Card>
            </Col>

            <Col
              md={4}
              className=""
              style={{ paddingTop: '3.25rem', marginTop: '2rem' }}
            >
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center">
                  <img
                    src={work2}
                    className="rounded img-fluid d-block mx-auto hoveredImg"
                    alt=""
                    style={{ width: '400px' }}
                  />
                </div>

                <CardBody>
                  <h5 className="text-dark">{i18next.t('FactoryVisits')}</h5>
                </CardBody>
              </Card>
            </Col>

            <Col
              md={4}
              className=""
              style={{ paddingTop: '6rem', marginTop: '4rem' }}
            >
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center">
                  <img
                    src={work3}
                    className="rounded img-fluid d-block  mx-auto hoveredImg"
                    alt=""
                    style={{ width: '400px' }}
                  />
                </div>
                <CardBody>
                  <h5 className="text-dark">{i18next.t('TravelArrangements')}</h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Support;
